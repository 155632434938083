import { trpc } from '@utils/trpc'
import { useRouter } from 'next/router'
import type { FC } from 'react'

import { classNames } from '@people/ui/utils'

import { Navbar } from './navbar'
import { Meta, Seo } from './Seo'

export const Layout: FC<{ meta?: Meta }> = props => {
  const router = useRouter()
  const { meta, children } = props

  const { data: userInfo } = trpc.useQuery(['user.getUserInfoAndOrgs'], {
    retry: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  const defaultOrg = userInfo?.orgs.find(org => org.isDefault)

  if (!userInfo || !defaultOrg?.slug) {
    return null
  }

  return (
    <>
      <Seo meta={meta} />

      <Navbar userInfo={userInfo} defaultOrg={defaultOrg} />

      <div
        className={classNames(
          router.route !== '/calendar' ? ' pb-12 lg:pb-[120px] min-h-full' : '',
          'mx-auto  w-full overflow-hidden',
        )}
      >
        {children}
      </div>
    </>
  )
}
