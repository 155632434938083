import { useElementSize } from '@mantine/hooks'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { FC, ReactNode } from 'react'

import { classNames } from '../utils'

export interface DropdownPopProps {
  button: ReactNode
  items: ReactNode
  asChild?: boolean
  className?: string
  classNameTrigger?: string
}

export const DropdownPop: FC<DropdownPopProps> = ({
  button,
  items,
  asChild = false,
  className,
  classNameTrigger,
}) => {
  const { ref: triggerRef, width: widthTrigger } = useElementSize()

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger
        ref={triggerRef}
        className={classNameTrigger}
        asChild={asChild}
      >
        {button}
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          side="bottom"
          align="start"
          className={classNames('PopoverContent', className || '')}
          style={{
            zIndex: 9999,
            minWidth: `${widthTrigger}px`,
          }}
        >
          {items}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

export default DropdownPop
