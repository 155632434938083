import { OrganisationRole, Prisma } from '@prisma/client'

import {
  AccessActionType,
  ObjectLevelPermissions,
} from '../constants/permissions'

export const getEligibleRolesFromMinimumRequiredRole = (
  minRequiredRole: OrganisationRole,
): OrganisationRole[] => {
  switch (minRequiredRole) {
    case 'ADMIN':
      return ['ADMIN']
    case 'MANAGER':
      return ['ADMIN', 'MANAGER']
    case 'MEMBER':
      return ['ADMIN', 'MANAGER', 'MEMBER']
  }
}

export const getEligibleObjectAccessUserRoles = (
  objectType: Prisma.ModelName,
  action: AccessActionType,
): OrganisationRole[] => {
  const minRequiredRole = getMinRequiredRoleForAccessAction(objectType, action)

  const eligibleRoles = getEligibleRolesFromMinimumRequiredRole(minRequiredRole)

  return eligibleRoles
}

export const hasUserObjectAccess = (
  memberRole: OrganisationRole,
  objectData: {
    type: Prisma.ModelName
    action: AccessActionType
  },
): boolean => {
  const { type, action } = objectData

  const minRequiredRole = getMinRequiredRoleForAccessAction(type, action)

  const isAuthorized = isUserRoleAuthorized({
    memberRole: memberRole,
    minRequiredRole,
  })

  return isAuthorized
}

export const getMinRequiredRoleForAccessAction = (
  objectType: Prisma.ModelName,
  action: AccessActionType,
): OrganisationRole => {
  const minRequiredRole = ObjectLevelPermissions[objectType][action]
  return minRequiredRole
}

export const isUserRoleAuthorized = ({
  memberRole,
  minRequiredRole,
}: {
  memberRole?: OrganisationRole
  minRequiredRole: OrganisationRole
}) => {
  if (!memberRole) return false

  switch (minRequiredRole) {
    case 'ADMIN':
      return memberRole === 'ADMIN'
    case 'MANAGER':
      return memberRole === 'ADMIN' || memberRole === 'MANAGER'
    case 'MEMBER':
      return (
        memberRole === 'ADMIN' ||
        memberRole === 'MANAGER' ||
        memberRole === 'MEMBER'
      )
  }
}
