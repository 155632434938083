import type { FC } from 'react'

import IconProps from './IconProps'

export const AnalyticsIcon: FC<IconProps> = ({ className, ...others }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...others}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00008 0.5C6.46032 0.5 6.83341 0.873096 6.83341 1.33333V14.6667C6.83341 15.1269 6.46032 15.5 6.00008 15.5C5.53984 15.5 5.16675 15.1269 5.16675 14.6667V1.33333C5.16675 0.873096 5.53984 0.5 6.00008 0.5ZM11.0001 5.5C11.4603 5.5 11.8334 5.8731 11.8334 6.33333V14.6667C11.8334 15.1269 11.4603 15.5 11.0001 15.5C10.5398 15.5 10.1667 15.1269 10.1667 14.6667V6.33333C10.1667 5.8731 10.5398 5.5 11.0001 5.5ZM1.00008 8.83333C1.46032 8.83333 1.83341 9.20643 1.83341 9.66667V14.6667C1.83341 15.1269 1.46032 15.5 1.00008 15.5C0.539844 15.5 0.166748 15.1269 0.166748 14.6667V9.66667C0.166748 9.20643 0.539844 8.83333 1.00008 8.83333Z"
        fill="#2563EB"
      />
    </svg>
  )
}

export default AnalyticsIcon
