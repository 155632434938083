/* This example requires Tailwind CSS v2.0+ */
import { isDemoUser } from '@/utils/isDemoUser'
import {
  ArrowCircleLeftIcon,
  BriefcaseIcon,
  CalendarIcon,
  ChatAlt2Icon,
  CogIcon,
  InformationCircleIcon,
  SearchIcon,
} from '@heroicons/react/outline'
import { useAuth } from '@hooks/useAuth'
import { InferQueryOutput, trpc } from '@utils/trpc'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC } from 'react'
import { useIntercom } from 'react-use-intercom'

import TeeroLogo from '@people/ui/brand/TeeroLogo'
import { DropdownItem } from '@people/ui/dropdown'
import { Header } from '@people/ui/header/Header'
import { HeaderNavItem } from '@people/ui/header/HeaderNavItem'
import { AnalyticsIcon } from '@people/ui/icons'
import { ButtonSpinner } from '@people/ui/spinners'

import { useMemberRole } from '@/hooks/useMemberRole'
import { isUserRoleAuthorized } from '@people/common/utils/permissions'
import { UnderReview } from '../badges/UnderReview'
import { OrgSwitcher } from './OrgSwitcher'

type NavigationItem = {
  name?: string
  href: string
  icon: JSX.Element
  active: boolean
}

interface NavbarProps {
  userInfo: InferQueryOutput<'user.getUserInfoAndOrgs'>
  defaultOrg: {
    slug: string
    isVerified: boolean
  }
}

export const Navbar: FC<NavbarProps> = ({ userInfo, defaultOrg }) => {
  const { t } = useTranslation('common')
  const { show, hide, shutdown } = useIntercom()
  const { route } = useRouter()

  const { user, signOut } = useAuth()

  const { mutate: terminateSessions, isLoading: isTerminating } =
    trpc.useMutation('user.terminateSessions', {
      onSuccess: () => {
        signOut()
      },
    })

  const { memberRole } = useMemberRole(defaultOrg?.slug)

  if (!defaultOrg?.slug) {
    return null
  }

  const navigationItems: NavigationItem[] = [
    {
      name: t('search'),
      href: `/${defaultOrg?.slug}/search`,
      icon: <SearchIcon />,
      active: route === '/[orgSlug]/search',
    },
    {
      name: t('jobs'),
      href: `/${defaultOrg?.slug}/`,
      icon: <BriefcaseIcon />,
      active: route === '/[orgSlug]',
    },
    {
      name: t('calendar'),
      href: `/calendar`,
      icon: <CalendarIcon />,
      active: route === '/calendar',
    },
    {
      name: t('settings'),
      href: `/settings`,
      icon: <CogIcon />,
      active:
        route.startsWith('/settings') ||
        route.startsWith(`/[orgSlug]/settings/`),
    },
  ]

  if (
    isUserRoleAuthorized({
      memberRole,
      minRequiredRole: 'MANAGER',
    })
  ) {
    navigationItems.splice(navigationItems.length - 1, 0, {
      name: t('analytics'),
      href: `/${defaultOrg?.slug}/analytics`,
      icon: <AnalyticsIcon className="my-auto ml-auto" />,
      active: route === '/[orgSlug]/analytics',
    })
  }

  return (
    <Header
      logo={
        <Link href="/">
          <a className="block">
            <TeeroLogo />
          </a>
        </Link>
      }
      user={{
        name: user?.displayName || user?.email,
        email: user?.email,
        imageUrl: userInfo?.user?.image,
      }}
      userNav={[
        <>
          <DropdownItem
            onClick={() => {
              show()
            }}
          >
            <ChatAlt2Icon className="h-5 w-5 text-blue-600" />
            <p>{t('contactSupport')}</p>
          </DropdownItem>
          <a
            className="flex"
            href="https://support.teero.com/en/"
            target="_blank"
          >
            <DropdownItem>
              <InformationCircleIcon className="h-5 w-5 text-blue-600" />
              <p>{t('helpCenter')}</p>
            </DropdownItem>
          </a>
          <DropdownItem
            onClick={() => {
              shutdown()
              hide()
              terminateSessions()
            }}
          >
            {isTerminating ? (
              <ButtonSpinner className="w-5 h-5 text-blue-600 animate-spin" />
            ) : (
              <ArrowCircleLeftIcon className="h-5 w-5 text-blue-600" />
            )}
            <p>{t('signOut')}</p>
          </DropdownItem>
        </>,
      ]}
      demo={isDemoUser(user?.email)}
    >
      {navigationItems.map((item, itemIndex) => (
        <Link key={itemIndex} href={item.href}>
          <a className="flex w-full sm:w-auto">
            <HeaderNavItem {...item} />
          </a>
        </Link>
      ))}
      <div className="ml-auto items-center block sm:flex h-full">
        {defaultOrg && !defaultOrg?.isVerified && (
          <div className="sm:ml-0 ml-3 mb-2 sm:mb-0 mr-3">
            <UnderReview variant="info" />
          </div>
        )}
        <OrgSwitcher />
      </div>
    </Header>
  )
}
