import { InformationCircleIcon, RefreshIcon } from '@heroicons/react/outline'
import type { FC } from 'react'

import { Badge } from '@people/ui/badges'
import { Tooltip } from '@people/ui/tooltips'

export interface UnderReviewProps {
  variant: 'blue-outline' | 'info'
}

export const UnderReview: FC<UnderReviewProps> = ({ variant }) => {
  return (
    <Tooltip
      trigger={
        <button>
          <Badge variant={variant}>
            <div className="flex items-center gap-1">
              <RefreshIcon className="h-3.5 w-3.5" />
              Under review
              <InformationCircleIcon className=" h-3.5 w-3.5" />
            </div>
          </Badge>
        </button>
      }
    >
      It takes up to 24h for us to review your company. We will notify you once
      it's done.
    </Tooltip>
  )
}
