import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import { FC, ReactNode } from 'react'

import { Badge } from '../badges'
import { Card } from '../cards'
import { DropdownPop } from '../dropdown'
import { classNames } from '../utils'

export interface HeaderProps {
  className?: string
  logo?: ReactNode
  user?: {
    name?: string | null
    email?: string | null
    imageUrl?: string | null
  }
  userNav?: ReactNode[]
  demo?: boolean
}

export const Header: FC<HeaderProps> = ({
  className,
  logo,
  user,
  userNav,
  children,
  demo,
}) => {
  return (
    <>
      <div className="w-full h-[52px] bg-white shadow relative z-20 lg:fixed">
        <Disclosure
          as="nav"
          className={classNames(
            className ?? '',
            'flex max-w-[1440px] w-full mx-auto items-center justify-between h-[52px] md:justify-start relative',
          )}
        >
          {({ open: openMobileMenu }) => (
            <>
              <div className="inset-y-0 left-0 flex items-center border-r h-full px-3 md:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-3 text-blue-600 hover:bg-blue-50  hover:text-blue-700 focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {openMobileMenu ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>

              <div className="left-0 mx-auto flex h-full w-full items-center justify-center px-[18px] py-5 md:inline-flex md:w-auto border-r border-r-slate-200">
                {logo}
              </div>

              <span className="hidden flex-1 items-center self-center md:flex flex-row gap-2.5 px-5 py-2.5">
                {children}
              </span>

              <Disclosure.Panel className="md:hidden">
                <div className="absolute left-0 top-[52px] z-50 w-full space-y-1 border-t border-b bg-white px-2 pt-2 pb-3 shadow-md">
                  {children}
                </div>
              </Disclosure.Panel>

              {user && (
                <DropdownPop
                  button={
                    <div className="flex items-center relative  sm:border-l py-3.5 px-5">
                      {demo && (
                        <Badge variant="success" size="md" className="mr-2">
                          DEMO
                        </Badge>
                      )}
                      {user?.imageUrl && (
                        <div className="mr-2.5 flex h-6 w-6 min-w-[24px] min-h-[24px] items-center justify-center overflow-hidden rounded-full bg-slate-200">
                          {user?.imageUrl ? (
                            <img src={user.imageUrl} alt="" />
                          ) : (
                            <>
                              {user?.name
                                ?.split(' ')
                                .map(partName => partName.slice(0, 1))
                                .join('')}
                            </>
                          )}
                        </div>
                      )}

                      <span className="mr-2.5 hidden text-base font-medium text-slate-900 lg:block whitespace-nowrap max-w-[200px] text-ellipsis overflow-hidden ">
                        {user?.name}
                      </span>

                      {userNav && (
                        <ChevronDownIcon className="h-[18px] w-[18px] text-slate-400" />
                      )}
                    </div>
                  }
                  items={
                    <Card className="mt-1">
                      {userNav?.map((userNavItem, idx) => (
                        <div className="space-y-1.5 p-1" key={idx}>
                          {userNavItem}
                        </div>
                      ))}
                    </Card>
                  }
                />
              )}
            </>
          )}
        </Disclosure>
      </div>
      <div className="hidden lg:block lg:h-[52px]" />
    </>
  )
}

export default Header
