import { Disclosure } from '@headlessui/react'
import { FC, ReactNode } from 'react'

import { classNames } from '../utils/classNames'

export interface HeaderNavItemProps {
  name?: string
  icon: ReactNode
  active?: boolean
}

export const HeaderNavItem: FC<HeaderNavItemProps> = ({
  name,
  icon,
  active,
}) => {
  return (
    <>
      <Disclosure.Button
        className={classNames(
          active ? 'bg-blue-50 text-blue-600' : 'text-black hover:bg-blue-50',
          'my-2 flex min-w-[140px] flex-row items-center rounded-md px-3 py-2 text-base font-medium sm:hidden w-full sm:w-auto',
        )}
        aria-current={active ? 'page' : undefined}
      >
        <span
          className={classNames(
            name ? 'mr-2' : '',
            'flex h-5 w-5 text-blue-600 min-w-[20px] min-h-[20px] ',
          )}
        >
          {icon}
        </span>
        {name}
      </Disclosure.Button>
      <div
        className={classNames(
          active ? 'bg-blue-50 text-blue-600' : 'text-black hover:bg-blue-50',
          'hidden flex-row items-center rounded-md px-2.5 py-1.5 text-base font-medium sm:flex',
        )}
        aria-current={active}
      >
        <span
          className={classNames(
            name ? 'mr-2' : '',
            'flex h-5 w-5 text-blue-600',
          )}
        >
          {icon}
        </span>
        <p>{name}</p>
      </div>
    </>
  )
}

export default HeaderNavItem
