import { trpc } from '@/utils/trpc'

export const useMemberRole = (orgSlug?: string) => {
  const { data: memberRole, isLoading: isLoadingMemberRole } = trpc.useQuery(
    [
      'org.member.getRole',
      {
        orgSlug: orgSlug as string,
      },
    ],
    {
      enabled: Boolean(orgSlug),
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  )

  return {
    memberRole,
    isLoadingMemberRole,
  }
}
