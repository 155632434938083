import { OrganisationRole, Prisma } from '@prisma/client'

export type AccessActionType = 'create' | 'read' | 'update' | 'delete'

export const ObjectLevelPermissions: Readonly<
  Record<Prisma.ModelName, Record<AccessActionType, OrganisationRole>>
> = {
  AtsJob: {
    create: 'MANAGER',
    delete: 'MANAGER',
    read: 'MEMBER',
    update: 'MANAGER',
  },
  AtsJobStage: {
    create: 'MANAGER',
    delete: 'MANAGER',
    read: 'MEMBER',
    update: 'MANAGER',
  },
  User: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  NylasAccount: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  NylasEvent: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  NylasScheduler: {
    read: 'MEMBER',
    create: 'MANAGER',
    delete: 'MANAGER',
    update: 'MEMBER',
  },
  NylasSchedulerInterviewer: {
    read: 'MEMBER',
    create: 'MEMBER',
    delete: 'MEMBER',
    update: 'MEMBER',
  },
  NylasSchedulerInterviewOpeningHours: {
    read: 'MEMBER',
    create: 'MEMBER',
    delete: 'MEMBER',
    update: 'MEMBER',
  },
  NylasCalendar: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'MEMBER',
  },
  VerificationToken: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  UserOnOrganisation: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  UserOnOrgLocation: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  Organisation: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  OrganisationLocation: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'MANAGER',
  },
  Country: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  AtsApplicant: {
    read: 'MEMBER',
    create: 'MANAGER',
    delete: 'MANAGER',
    update: 'MEMBER',
  },
  AtsApplicantSource: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  AtsApplicantPhoneNumber: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  AtsPosition: {
    read: 'MEMBER',
    create: 'MANAGER',
    delete: 'MANAGER',
    update: 'MANAGER',
  },
  UserOnJob: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  AtsJobStageQuestion: {
    read: 'MEMBER',
    create: 'MANAGER',
    delete: 'MANAGER',
    update: 'MANAGER',
  },
  AtsJobStageQuestionAnswer: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  AtsApplicantStageStatus: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  Integration: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  OrganisationIntegration: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  JobBoard: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  JobOnJobBoard: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  JobBoardOnCountry: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  OrganisationDefaultJobBoard: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  JobOnLandingPage: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  LandingPage: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  OrganisationActionReason: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  MessageSequence: {
    read: 'MEMBER',
    create: 'MANAGER',
    delete: 'MANAGER',
    update: 'MANAGER',
  },
  EmailAttachment: {
    read: 'MEMBER',
    create: 'MANAGER',
    delete: 'MANAGER',
    update: 'MANAGER',
  },
  MessageSequenceStageDefault: {
    read: 'MEMBER',
    create: 'MANAGER',
    delete: 'MANAGER',
    update: 'MANAGER',
  },
  MessageSequenceStepSchedule: {
    read: 'MEMBER',
    create: 'MANAGER',
    delete: 'MANAGER',
    update: 'MANAGER',
  },
  MessageSequenceStep: {
    read: 'MEMBER',
    create: 'MANAGER',
    delete: 'MANAGER',
    update: 'MANAGER',
  },
  ApplicantChat: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  ApplicantChatMessage: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  ApplicantStageMessageSequenceHistory: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  NewsletterSubscriptions: {
    read: 'ADMIN',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  WhitelistedDomains: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  Webhook: {
    read: 'MEMBER',
    create: 'MANAGER',
    delete: 'MANAGER',
    update: 'MANAGER',
  },
  WebhookStageDefault: {
    read: 'MEMBER',
    create: 'MANAGER',
    delete: 'MANAGER',
    update: 'MANAGER',
  },
  OrganisationBranding: {
    read: 'MEMBER',
    create: 'MANAGER',
    delete: 'ADMIN',
    update: 'MANAGER',
  },
  ApiKey: {
    read: 'ADMIN',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  EventLog: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  Rule: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  RuleCondition: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  RuleCustomField: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  AtsApplicantCustomField: {
    read: 'MEMBER',
    create: 'MANAGER',
    delete: 'MANAGER',
    update: 'MANAGER',
  },
  DataKey: {
    read: 'MEMBER',
    create: 'MANAGER',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  Document: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  DocumentApplicantStage: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  DocumentOnDataKey: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  ApplicantNote: {
    read: 'MEMBER',
    create: 'MEMBER',
    delete: 'MEMBER',
    update: 'MEMBER',
  },
  UnsubscribedEmail: {
    read: 'MEMBER',
    create: 'ADMIN',
    delete: 'ADMIN',
    update: 'ADMIN',
  },
  CalcomApi: {
    read: 'MEMBER',
    create: 'MEMBER',
    delete: 'MEMBER',
    update: 'MEMBER',
  },
  CalcomEventType: {
    read: 'MEMBER',
    create: 'MEMBER',
    delete: 'MEMBER',
    update: 'MEMBER',
  },
  CalcomEvent: {
    read: 'MEMBER',
    create: 'MEMBER',
    delete: 'MEMBER',
    update: 'MEMBER',
  },
}
