import * as TooltipRadix from '@radix-ui/react-tooltip'
import type { FC, ReactNode } from 'react'

import { classNames } from '../utils'

export interface TooltipProps {
  className?: string
  trigger: ReactNode
  children?: ReactNode
}

export const Tooltip: FC<TooltipProps> = ({ className, trigger, children }) => {
  if (!children) return <>{trigger}</>

  return (
    <TooltipRadix.Provider>
      <TooltipRadix.Root delayDuration={0}>
        <TooltipRadix.Trigger asChild>{trigger}</TooltipRadix.Trigger>
        <TooltipRadix.Portal>
          <TooltipRadix.Content
            side="bottom"
            className={classNames(
              ' bg-slate-900 rounded-md p-1.5 text-sm text-white text-center max-w-[200px] z-30',
              className || '',
            )}
            sideOffset={5}
          >
            {children}
            <TooltipRadix.Arrow className="TooltipArrow" />
          </TooltipRadix.Content>
        </TooltipRadix.Portal>
      </TooltipRadix.Root>
    </TooltipRadix.Provider>
  )
}

export default Tooltip
